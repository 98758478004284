@charset "UTF-8";

// DATATABLE STYLING
.dataTables_header {
    .dataTables_filter {
        float: none;
        text-align: inherit;
        line-height: 0;
    }
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0px;
    .dataTables_filter {
        position: relative;
        flex-grow: 1;
        margin-right: 12px;
        label {
            margin-bottom: 0px;
            width: 100%;
        }
        input {
            margin: 0;
            width: 100%;
            display: block;
            height: 36px;
            border: $border-width solid $border-color;
            border-radius: $border-radius;
            padding: 0px 12px;
            &:focus {
                outline: none;
                border-color: $primary;
            }
        }
    }
    .dataTables_length {
        margin-right: 12px;
        label {
            margin-bottom: 0px;
        }
        select {
            margin: 0;
            display: block;
            height: 36px;
            border: $border-width solid $border-color;
            border-radius: $border-radius;
            padding: 0px 12px;
            &:focus {
                outline: none;
                border-color: $primary;
            }
        }
    }
    .dataTables_paginate {
        padding: 0px;
        display: flex;
        .paginate_button {
            margin: 0 2px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            position: relative;
            overflow: hidden;
            padding: 8px;
            width: 36px;
            height: 36px;
            border-radius: $border-radius;
            text-decoration: none;
            text-transform: uppercase;
            font-size: 11px;
            outline: none;
            cursor: pointer!important;
            transition: $transition-base;
            background: $white;
            font-weight: 600;
            border: 1px solid $gray-200;
            &.disabled {
                background: $white;
                border: 1px solid $gray-300;
                opacity: .5;
                pointer-events: none;
                &:hover {
                    background: $white;
                    border: 1px solid $gray-300;
                }
            }
            &.previous,
            &.next {
                width: auto;
            }
            &.current {
                background: $secondary;
                color: $white;
                border: $border-width solid $secondary;
                pointer-events: none;
                &:hover {
                    background: $secondary;
                    color: $white;
                    border: $border-width solid $secondary; 
                }
            }
            &:hover {
                background: $gray-200;
                color: $black!important;
                border: $border-width solid $gray-300;
            }
        }
        a {
            color: $black;
        }
    }
}
.dataTables_footer {
    display: flex;
    width: 100%;
    justify-content: center;
    .dataTables_info {
        font-size: .8rem;
        color: $gray-500;
        padding-top: 0px;
        .select-info {
            margin-left: 4px;
            &:before {
                content: "- "
            }
        }
    }
}
table {
    &.dataTable {
        font-size: $font-size-sm;
        border-spacing: 0 8px;
        thead {
            th,
            td {
                border-bottom: 0px;
                text-align: left;
                &.nowrap {
                    white-space: nowrap;
                }
            }
            th {
                font-weight: 600;
                .inner {
                    display: flex;
                    align-items: center;
                    .material-icons {
                        color: $gray-200;
                        margin-right: 2px;
                        transform: translateY(-1px);
                    }
                }
                &:hover {
                    background-color: $gray-300!important;
                }
            }
            tr {
                background-color: $gray-300!important;
                border: $border-width solid $border-color;
                &:hover {
                    background-color: $gray-300!important;
                }
                th {
                    &:first-child {
                        border-left: $border-width solid $gray-300;
                        border-top-left-radius: $border-radius;
                        border-bottom-left-radius: $border-radius;
                    }
                    &:last-child {
                        border-right: $border-width solid $gray-300;
                        border-top-right-radius: $border-radius;
                        border-bottom-right-radius: $border-radius;
                    }
                    &:hover {
                        background-color: $gray-300!important;
                    }
                }
            }
            th {
                padding-left: 12px!important;
            }
        }
        tbody {
            tr {
                background-color: $white;
                transition: $transition-base;
                border-radius: $border-radius;
                border: $border-width solid $border-color;
                position: relative;
                td {
                    padding: 8px 12px;
                    border-top: 0px;
                    border-top: $border-width solid $border-color!important;
                    border-bottom: $border-width solid $border-color;
                    &:first-child {
                        border-left: $border-width solid $border-color;
                        border-top-left-radius: $border-radius;
                        border-bottom-left-radius: $border-radius;
                    }
                    &:last-child {
                        border-right: $border-width solid $border-color;
                        border-top-right-radius: $border-radius;
                        border-bottom-right-radius: $border-radius;
                    }
                    &.number{
                        width: 20px;
                        text-align: center;
                    }
                    &.nowrap {
                        white-space: nowrap;
                    }
                }
                &:hover {
                    box-shadow: $box-shadow-sm;
                }
                &.even {
                    td {
                        background-color: $white;
                    }
                    &:hover {
                        // td {
                        //     background-color: $white;
                        // }
                        .sorting_1 {
                            background: #fafafa;
                        }
                    }
                    
                }
                &.even,
                &.odd {
                    &:hover {
                        background: $gray-100!important;
                        td {
                            background: transparent!important;
                        }
                    }
                }
                &.selected {
                    &.even,
                    &.odd {
                        &:hover {
                            background: $primary!important;
                            td {
                                background: $primary!important;
                            }
                        }
                    }
                }
                // &:hover {
                //     background: $white!important;
                // }
            }
        }
        &.no-footer {
            border-bottom: 0px;
        }
        &.display {
            tbody {
                tr {
                    &.odd,
                    &.even {
                        background-color: $white;
                        &.selected {
                            background-color: $white;
                            td {
                                background-color: $primary;
                                border-color: $primary!important;
                                color: $white;
                                font-weight: 600;
                                // .btn-neutral {
                                //     border-color: $white;
                                //     color: $white;
                                //     transition: 0s;
                                //     .fas {
                                //         color: $white;
                                //     }
                                //     &:hover {
                                //         background: $white;
                                //         color: $black;
                                //         .fas {
                                //             color: $black;
                                //         }
                                //     }
                                // }
                                &:hover {
                                    background: $primary!important;
                                    td {
                                        background: $primary!important;
                                    }
                                }
                            }
                        }
                    }
                }
                td {
                    border-top: 0px;
                    &.colspan-solution {
                        border-right: 1px solid $gray-400;
                        border-top-right-radius: $border-radius;
                        border-bottom-right-radius: $border-radius;
                    }
                }
            }
        }
    }
}
table.dataTable.display tbody tr.odd>.sorting_1, table.dataTable.order-column.stripe tbody tr.odd>.sorting_1 {
    background: #fafafa;
}

div {
    &.dt-buttons {
        display: flex;
        align-items: center;
        margin-right: 10px;
    }
}
button{
    &.dt-button {
        height: 36px;
        margin: 0px;
        border: $border-width solid $border-color;
        border-radius: $border-radius;
        background-color: $white;
        font-size: .8rem;
        font-weight: 600;
        background-image: none;
        margin-right: 4px;
        transition: $transition-base;
        &.disabled {
            background: $white;
            border-color: $gray-300;
            opacity: .5;
        }
        &:hover {
            border-color: $border-color!important;
            background-color: $gray-200!important;
            background-image: none!important;
        }
    }
}