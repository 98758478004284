@charset "UTF-8";

.select2-group {
    display: flex;
}
.select2-group__1 {
    width: 35%;
    .select2 {
        &:nth-child(2) {
            margin-right: 8px;
        }
        
    }
}
.select2-container {
    width: 100%!important;
    &:focus,
    &:active {
        outline: none!important;
    }
    .select2-selection {
        &.select2-selection--single {
            height: 42px;
            font-size: $font-size-base;
            border: $border-width solid $border-color;
            border-radius: $border-radius;
            display: flex;
            align-items: center;
            background: $white;
            .select2-selection__rendered {
                padding-left: 12px;
                width: 100%;
            }
            .select2-selection__arrow {
                b {
                    border-color: $gray-900 transparent transparent transparent;
                }
            }
        }
        &:focus,
        &:active {
            outline: none!important;
        }
        .select2-selection__arrow {
            height: 42px;
            top: 0px;
            right: 8px;
        }
    }
    .select2-search--inline {
        margin-top: 5px;
        .select2-search__field {
            height: 32px;
            margin-top: 0px;
        }
    }
    &.select2-container--open {
        .select2-selection--single {
            border: $border-width solid $primary;
            border-bottom: 0px;
            .select2-selection__arrow {
                b {
                    border-color: transparent transparent $gray-900 transparent;
                }
            }
        }
        .select2-dropdown {
            border: $border-width solid $primary!important;
        }
    }
}

// DROPDOWN

.select2-dropdown {
    border: $border-width solid $border-color;
    border-radius: $border-radius;
    color: $black;
    overflow: hidden;
}
.select2-results__option {
    font-size: $font-size-sm;
    padding: 6px 12px;
}
.select2-container--default .select2-results__option--highlighted[aria-selected] {
    background: $primary;
}
.select2-container--default .select2-results__option[aria-selected=true] {
    background: $gray-200;
    color: $black;
    &:hover {
        background: $primary;
        color: $white;
    }
}
.select2-search--dropdown {
    padding: 0px;
    .select2-search__field {
        height: 42px;
        border: 0px!important;
        border-radius: 0px!important;
        padding: 0px 12px;
        background: transparent!important;
    }
}
.select2-results__message {
    font-size: $font-size-sm;
    color: $gray-400;
}

// SELECT MULTIPLE
.select2-container, .select2-container--default {
    .select2-selection--multiple {
        font-size: $font-size-base;
        border: $border-width solid $gray-200;
        border-radius: $border-radius;
        .select2-selection__rendered {
            min-height: 42px;
            display: flex;
            align-items: flex-start;
            flex-wrap: wrap;
            padding: 0px 12px;
            padding-bottom: 5px;
        }
        .select2-selection__choice {
            height: 32px;
            padding: 0px 8px;
            display: flex;
            align-items: center;
            font-size: $font-size-base;
            background: $primary;
            color: $white;
            border: 0px;
        }
        .select2-selection__choice__remove {
            font-size: 16px;
            margin-right: 6px;
            color: $white;
            &:hover {
                color: $gray-300;
            }
        }
    }
    &.select2-container--focus {
        .select2-selection--multiple {
            border: $border-width solid $border-color;
        }
    }
}
// Select Auto
.select-width-auto {
    .select2-container {
        width: auto!important;
        .select2-selection {
            .select2-selection__rendered {
                padding-right: 36px;
            }
        }
    }
}
.select2-big {
    border: 0px!important;
    border-radius: 0px!important;
    height: 56px!important;
    .select2-selection__rendered {
        font-size: 28px;
        font-weight: 600;
        padding-left: 0px!important;
    }
    .select2-selection__arrow {
        height: 56px!important;
    }
}
