/*! `Poypull` Bootstrap 4 theme */
@import url(https://fonts.googleapis.com/css?family=Muli:200,300,400,700);
$headings-font-family:Muli;

$border-radius: 4px;

$gray-100: #F9FBFC;
$gray-200: #EBF0F5;
$gray-300: #D6DEE6;
$gray-400: #C5D0DB;
$gray-500: #A0B0BF;
$gray-600: #60707D;
$gray-700: #434E59;
$gray-800: #313B45;
$gray-900: #1D242B;

$primary:#7F0FFF;
$secondary:#bed6d5;
$success:#420084;
$danger:#f2460d;
$info:#7ebcfa;
$warning:#ff9933;
$light:#eef0f2;
$dark:#000633;
$display1-weight:600;
$display2-weight:600;
$display3-weight:600;
$navbar-dark-color:#f3f3f3;

$border-color: #C5D0DB;

@import "../node_modules/bootstrap/scss/bootstrap";

@import "scss/vendor/datatables/datatables";
@import "scss/vendor/select2/select2-corpora";

$pretty--color-primary:#7F0FFF;

@import "../node_modules/pretty-checkbox/src/pretty-checkbox.scss";

$box-shadow-primary: 0 .5rem 1rem $primary;

@font-face {
    font-family: 'Font Awesome 5 Free';
    font-style: normal;
    font-weight: 900;
    font-display: auto;
    src: url("../webfonts/fa-solid-900.eot");
    src: url("../webfonts/fa-solid-900.eot?#iefix") format("embedded-opentype"), url("../webfonts/fa-solid-900.woff2") format("woff2"), url("../webfonts/fa-solid-900.woff") format("woff"), url("../webfonts/fa-solid-900.ttf") format("truetype"), url("../webfonts/fa-solid-900.svg#fontawesome") format("svg"); }
    
@mixin full-background {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

*:focus,
input:focus {
    outline:0px !important;
    box-shadow: none !important;
}
textarea:hover, 
input:hover, 
textarea:active, 
input:active, 
textarea:focus, 
input:focus,
button:focus,
button:active,
button:hover,
label:focus,
.btn:active,
.btn.active
{
    outline:0px !important;
    -webkit-appearance:none;
    box-shadow: none !important;
}

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {font-weight: 600;}
hr {
    margin-top: 8px;
    margin-bottom: 8px;
}
html,
body {
  height: 100%;
}
body {
    background: $gray-100;
    overflow-x: hidden;
    font-family: 'Open Sans', sans-serif;
    color: $gray-800;
}
body, 
.form-control,
.select2-selection--single {
    font-size: $font-size-sm!important;
}
// BACKGROUND
.bg-gray-200 {
    background: $gray-200;
}
.bg-gray-300 {
    background: $gray-300;
}
// FONT AWESOME
.fas {
    color: $gray-600;
}
// EDIT BOOTSTRAP BUTTON
.btn{
    min-width: 42px;
    height: 42px;
    font-size: .8rem;
    font-weight: 600;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding-left: .75rem;
    padding-right: .75rem;
    &.btn-neutral {
        border-color: $border-color;
        background-color: $white;
        color: $gray-800;
        &:hover {
            background-color: $gray-200;
            color: $gray-800;
        }
    }
    &.btn-primary{
        .fas {
            color: $white;
        }
    }
    &.btn-warning {
        .fas {
            color: $black;
        }
    }
    &.btn-outline-primary,
    &.btn-outline-secondary {
        &:hover {
            .fas {
                color: $white;
            }
        }
    }
    &.btn-outline-primary {
        .fas {
            color: $primary;
        }
    }
    &.btn-outline-secondary {
        .fas {
            color: $secondary;
        }
    }
    &.btn-block {
        display: flex;
    }
    &.btn-sm {
        min-width: inherit;
        height: inherit;
    }
}
.btn-link-black {
    color: $black!important;
}
.btn-link-neutral {
    color: $gray-800!important;
}
.btn-link-muted {
    color: $gray-600!important;
}
// HEADER BANNER
.header-banner {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    // width: 100%;
    // margin-left: -18px;
    // margin-right: -18px;
    margin-top: -18px;
    margin-bottom: 48px;
    height: 138px;
    // padding: 0px 18px;
    //
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    //
    margin-left: -32px;
    margin-right: -32px;
    padding: 0px 32px;
    &.header-banner-responsive {
        height: inherit;
        padding-top: 48px;
        h2 {
            color: $white;
        }
    }
    &.header-banner-card {
        margin-left: -1.5rem;
        margin-right: -1.5rem;
        margin-top: -1.5rem;
        margin-bottom: 1.5rem;
    }
}
.header-banner-title {
    width: 70%;
}
// CARD
.card {
    border-color: $gray-300!important;
    // box-shadow: 0 19px 38px rgba(0,0,0,0.04), 0 15px 12px rgba(0,0,0,0.10)!important;
    &.card-block {
        width: 100%;
    }
    &.card-clip {
        overflow: hidden;
    }
}
.card-banner {
    margin-bottom: -24px;
    box-shadow: $box-shadow-sm!important;
}
.card-row {
    display: flex;
    flex-wrap: wrap;
    margin-left: -8px;
    margin-right: -8px;
}
.card-col {
    padding-left: 8px;
    padding-right: 8px;
    display: flex;
    margin-bottom: 12px;
    &.card-100 {
        width: 100%;
        margin-bottom: 16px;
    }
    &.card-25 {
        width: 25%;
        margin-bottom: 16px;
    }
}
.card-header,
.card-body,
.card-footer {
    padding: 12px;
    position: relative;
}
.card-header{
    background-color: $gray-200;
    &.card-header-muted {
        color: $gray-600;
        background: $white;
        border-bottom: 0px;
        padding-bottom: 0px;
    }
}
.card-footer {
    background: $white;
    border-top: 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
// INPUT 
.col-form-label {
    font-weight: 600;
}
.form-control {
    height: 42px;
    background: $white;
    border-color: $border-color;
    &:focus {
        border-color: $primary;
    }
    // &.form-control-bg-white {
    //     background: $white;
    // }
}
.custom-select {
    height: 42px;
    font-size: $font-size-sm;
    &:focus {
        border-color: $primary;
    }
}
// BADGE
.badge {
    font-weight: 600;
    border-radius: $border-radius;
    font-size: .6rem;
    text-transform: uppercase;
    padding: .5em .9em;
    line-height: 1.3;
    &.badge-secondary-outline {
        color: $black;
        border: $border-width solid $gray-600;
    }
}
.right-answer {
    font-weight: 600;
    &.pretty {
        .state{
            &:before {
                content: '';
                position: absolute;
                top: -12px;
                bottom: -12px;
                left: -12px;
                right: 0px;
                background: $gray-200;
                border-radius: $border-radius;
            }
            label {
                position: relative;
                font-weight: 600;
            }
        }   
    }
    
}
.right-answer-text {
    position: relative;
    display: block;
    font-weight: 600;
    color: $primary;
    padding-left: 23px;
    margin-top: 12px;
    margin-bottom: 4px;
    font-size: .76rem;
    text-transform: uppercase;
}
// USER PHOTO
.user__group {
    .user__photos {
        @include full-background;
        background-color: $gray-300;
        height: 40px;
        width: 40px;
        border-radius: 40px;
    }
    .user__text {
        h4 {
            font-weight: 600;
        }
    }
}
.user-profile-photos {
    @include full-background;
    display: block;
    background-color: $gray-300;
    height: 100px;
    width: 100px;
    border-radius: 100px;
    &.user-profile-photos-sm {
        height: 60px;
        width: 60px;
    }
}
// COLLAPSE
.sidebar__nav_link,
.sidebar__nav-list-link {
    &.on-collapsed {
        display: flex!important;
        justify-content: space-between!important;
        align-items: center!important;
        width: 100%!important;
    }
}
.sidebar__nav_link,
.sidebar__nav-list-link {
    &.on-collapsed[data-toggle=collapse] {
        .fas {
            transition: $transition-base;
        }
    }
}
.sidebar__nav_link,
.sidebar__nav-list-link {
    &.on-collapsed[data-toggle=collapse][aria-expanded=true] {
        .fas {
            transform: rotate(-180deg);
        }
    }
}
.sidebar__nav_wrapper  {
    &.on-collapse {
        padding-top: 0px;
        padding-bottom: 0px;
        .sidebar__nav_group {
            padding-top: 12px;
            padding-bottom: 12px;
        }
    }
}
// NAV TABS
.nav-tabs {
    .nav-link {
        &.active {
            background-color: transparent;
        }
    }
}
.list-numberz {
    margin: 0;
    padding: 0;
    padding-left: 16px;
    li {
        list-style: decimal;
        padding-left: 6px;
    }
}
.border-bottom {
    border-bottom: 1px solid $gray-300!important;
}
.border-top {
    border-top: 1px solid $gray-300!important;
}
// LINK
.link-primary {
    color: $primary;
    .fas {
        color: $primary;
    }
}
// LIST GROUP
.list-group-item {
    padding: .7rem 12px;
    border-color: $gray-300;
}
// HR
hr {
    border-color: $gray-300;
}
// FRAME
.frame__wrapper {
    display: flex;
    width: 100%;
    position: relative;
    .frame__sidebar {
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        // overflow-y: hidden;
        width: 212px;
        background: $gray-200;
        border-right: $border-width solid $border-color;
        z-index: 1;
        //
        display: flex;
        flex-direction: column;
        &.hidden {
            width: 65px;
            justify-content: space-between;
        }
        .frame__sidebar_header {
            padding: 12px;
            border-bottom: $border-width solid $gray-300;
            flex-shrink: 0;
        }
        .frame__sidebar_body {
            height: 100%;
            border-bottom: $border-width solid $gray-300;
            overflow-y: auto;
            &.hidde {
                display: none;
            }
        }
        .frame__sidebar_footer {
            flex-shrink: 0;
            padding: 12px;
            display: block;
            &.frame__hidder {
                cursor: pointer;
                &:hover {
                    background: $gray-200;
                }
            }
        }
    }
    .frame__primary_view {
        display: block;
        width: 100%;
        position: relative;
        padding-left: 212px;
        background: $gray-100;
        .frame__primary_body {
            padding: 16px 32px;
        }
        &.hidden {
            padding-left: 65px;
        }
    }
}
.frame-full {
    background: $gray-100;
    min-height: 100%;
    display: block;
    .frame-centered {
        display: flex;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        align-items: center;
    }
}
.text-sm {
    font-size: .8rem;
}
.form-icon {
    position: relative;
    width: 100%;
    .form-control {
        padding-right: 36px!important;
    }
    .fas {
        position: absolute;
        top: 14px;
        right: 14px;
        opacity: .6;
    }
}

// SIDEBAR NAVIGATION
.sidebar__nav_link {
    padding: 12px;
    // padding-left: 8px;
    transition: $transition-base;
    text-decoration: none;
    border-bottom: $border-width solid $gray-300;
    color: $black;
    font-weight: 600;
    display: flex;
    align-items: center;
    width: 100%;
    font-size: $font-size-sm;
    position: relative;
    &:hover {
        background: $gray-100;
        text-decoration: none;
        color: $black;
    }
    .fas {
        width: 24px;
        text-align: center;
    }
    &.active {
        background: $gray-100;
        color: $primary;
        .fas {
            color: $primary;
        }
    }
}
.sidebar__nav_wrapper {
    padding: 12px;
    transition: $transition-base;
    border-bottom: $border-width solid $gray-300;
    display: block;
    width: 100%;
    &:hover {
        background: $gray-200;
    }
    .sidebar__nav_title {
        padding: 4px 0px;
        padding-left: 8px;
        display: block;
        margin-bottom: 4px;
        font-size: $font-size-sm;
        font-weight: 600;
        font-size: $font-size-sm;
        position: relative;
    }
    .sidebar__nav_group {
        margin: 0;
        padding: 0;
        .sidebar__nav_list {
            list-style: none;
            a {
                display: block;
                padding: 6px 8px;
                border: $border-width solid transparent;
                border-radius: $border-radius;
                font-size: .8rem;
                background: transparent;
                text-decoration: none;
                color: $black;
                margin-bottom: 2px;
                transition: $transition-base;
                &:hover {
                    background: $gray-100;
                    border-color: $border-color;
                    text-decoration: none;
                }
            }
            &.active {
                a {
                    background: $gray-100;
                    color: $primary;
                    border-color: $border-color;
                    box-shadow: $box-shadow-sm;
                    font-weight: 600;
                    // &:hover {
                    //     background: $link-hover-color;
                    //     border-color: $link-hover-color;
                    // }
                }
            }
            &:last-child {
                a {
                    margin-bottom: 0px;
                }
            }
        }
    }
}

// BREADCRUMB 
.breadcrumb {
    padding: 15px 0px 12px 0px;
    background: transparent;
    border-radius: 0px;
    border-bottom: $border-width solid $border-color;
    .breadcrumb-item {
        a {
            padding: 4px 8px;
            background: transparent;
            color: $black;
            border-radius: $border-radius;
            transition: $transition-base;
            font-size: $font-size-sm;
            &:hover {
                background: $gray-200;
                text-decoration: none;
                color: $black;
            }
            &[aria-current="page"] {
                cursor: default;
            }
        }
        
    }
    &.breadcrumb-white {
        .breadcrumb-item {
            a {
                color: $white;
                .fas {
                    color: $white;
                }
                &:hover {
                    background: $gray-200;
                    text-decoration: none;
                    color: $black;
                    .fas {
                        color: $black;
                    }
                }
            }
            &:before {
                color: $white;
            }
        }
    }
    &.breadcrumb-naked {
        margin-bottom: 0px;
        border: 0px;
    }
}
// CK EDITOR
.cke_editable{
    padding: 16px;
    border:$border-width solid $border-color;
    border-radius: $border-radius;
    min-height: 100px;
    outline: none;
    transition: $transition-base;
    &:focus{
        border-color: $primary;
    }
    p{
        margin: 0;
        padding: 0;
        font-weight: 400!important;
    }
}
// SWEETALERT
.swal2-container {
    display: block;
    padding: 0px;
}
.swal2-popup {
    width: 100%;
    border-radius: 0px;
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
}
.swal2-title {
    color: $black;
}
.swal2-content {
    font-size: $font-size-base;
    color: $black;
}
.swal2-styled {
    padding: .375rem .75rem;
    font-size: $font-size-base;
    font-weight: $font-weight-base;
    border-radius: $border-radius;
    transition: $transition-base;
    &.swal2-confirm {
        background-color: $primary!important;
        border: $border-width solid $primary!important;
        font-size: $font-size-sm;
        transition: $transition-base;
        .fas {
            color: $white;
        }
    }
    &.swal2-cancel {
        background-color: $white!important;
        border: $border-width solid $border-color!important;
        color: $black;
        font-size: $font-size-sm;
        transition: $transition-base;
    }
}
// DROPDOWN MENU
.dropdown-menu {
    font-size: .875rem;
}
.dropdown-item {
    padding: .2rem .875rem;
}
// SIMPLE BAR
.simplebar-scrollbar {
    width: 4px;
    right: 4px;
}
.simplebar-track {
    .simplebar-scrollbar {
        &.simplebar-visible {
            &:before {
                opacity: .3;
            }
        }
    }
}
// BUTTON GROUP TEST
.btn-group-test {
    .btn {
        margin-right: 6px;
    }
}
// ANSWER OPTION
.answer-option {
    form {
        .pretty {
            display: block;
            width: 100%;
            margin: 20px 0px;
        }
    }
}
// CKE EDITABLE
.cke_editable {
    padding: .375rem .75rem;
}
// DATEPICKER
.datepicker {
    &.dropdown-menu {
        padding: 10px;
        min-width: 300px;
        // top: 190px!important;
    }
    td {
        width: 36px;
        height: 36px;
        &.active {
            background-color: $primary!important;
            color: $white!important;
            background-image: none!important;
            font-weight: 600;
        }
    }
}